/* eslint-disable react-hooks/rules-of-hooks */
import styled from 'styled-components'
import { useAiStore } from '../../stores/ai'
import useFormatText from '../../hooks/useFormatText'
import ContentCard, {
  ContentCardProps,
  ContentListType
} from '../ModalContentCard'
import { currencyItems } from '../../utils/currency'
import { calculateDiscountRate } from '../../utils/price'

const MarketingDataEditor = () => {
  const { aiInfos } = useAiStore()

  const currencyLabel =
    aiInfos?.priceInfo?.currency && currencyItems[aiInfos?.priceInfo?.currency]
  const currentFeature = aiInfos?.feature ? aiInfos?.feature[0] : undefined

  const ContentList: ContentCardProps[] = [
    { title: 'SIDE_NAV_MARKETING_CONTENT2', content: aiInfos?.name || '' },
    {
      title: 'SIDE_NAV_MARKETING_CONTENT3',
      contentList: currentFeature
        ? currentFeature?.reduce(
            (accumulator: ContentListType[], currentValue) => {
              if (currentValue.feature)
                accumulator.push({
                  title: currentValue.feature,
                  content: [currentValue.short, currentValue.detailed]
                })
              return accumulator
            },
            []
          )
        : undefined
    },
    {
      title: 'SIDE_NAV_MARKETING_CONTENT4',
      content: `${(
        aiInfos?.priceInfo?.originalPrice || ''
      )?.toLocaleString()} ${currencyLabel && useFormatText(currencyLabel)}`,
      notice:
        aiInfos?.priceInfo?.discountPrice ||
        aiInfos?.priceInfo?.discountPrice === 0
          ? `${useFormatText(
              'SIDE_NAV_MARKETING_CONTENT4_INFO1'
            )} ${aiInfos?.priceInfo?.discountPrice?.toLocaleString()} ${
              currencyLabel && useFormatText(currencyLabel)
            } / ${useFormatText(
              'SIDE_NAV_MARKETING_CONTENT4_INFO2'
            )} ${calculateDiscountRate(
              aiInfos?.priceInfo?.originalPrice || 0,
              aiInfos?.priceInfo?.discountPrice || 0
            )}%`
          : undefined
    }
  ]

  return (
    <Wrapper>
      {ContentList.map((content, idx) => (
        <ContentCard
          key={'marketing-data-list-' + idx}
          title={useFormatText(content.title)}
          content={content.content}
          contentList={content.contentList}
          notice={content.notice}
        />
      ))}
    </Wrapper>
  )
}

export default MarketingDataEditor

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  line-height: 1.5;
  padding-bottom: 60px;

  .content-card-title {
    color: ${({ theme }) => theme.colors.gray[400]};
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
  }
  .content-card-container {
    font-size: 16px !important;

    background-color: #f9f9f9;
  }

  .content-card-container-list {
    padding: 0;
    gap: 20px;
    .content-card-container-title {
      font-size: 18px;
    }
    ol {
      background-color: #f9f9f9;
      li {
        font-size: 16px;
      }
    }
  }

  .content-card-notice {
    padding: 0;
    color: #ff4c4c;
    font-size: 12px;
    font-weight: 600;
    line-height: 100%;
  }

  .persona-input {
    height: 40px;
    border-radius: 4px;
    input {
      width: 100%;
      height: 36px;
      font-size: 14px;
      line-height: 140%;
    }
  }
`
