import { useMutation } from 'react-query'
import { service } from '../../utils/api'
import { message } from 'antd'
import { PROJECT_STATUS } from '../../types/constants'
import { useIntl } from 'react-intl'
import { IconCircleCaution } from '../../assets/icons'
import { ToastProps } from '../../components/Toast'
import { RedirectProjectParams } from '../Main'

export const useRenderProjectMutation = (
  redirectProject: (_: RedirectProjectParams) => void,
  renderToast: (props: ToastProps) => void,
  onClose: () => void,
  toastTitle: string,
  toastDescription: string
) => {
  const intl = useIntl()

  const onError = (error: any) => {
    console.log('error', error)
    onClose()
    if (error instanceof Object && 'msg' in error && 'code' in error) {
      if (error.code === 'WAITING') {
        renderToast({
          icon: <IconCircleCaution />,
          title: toastTitle,
          description: toastDescription,
          duration: 4
        })
        return
      }
      if (typeof error.msg === 'string') {
        message.error(intl.formatMessage({ id: error.msg }))
      }
    }
  }

  const { mutate: renderMutate } = useMutation(
    (projectId: string) =>
      service().projects.changeStatus(projectId, PROJECT_STATUS.RENDER_REQUEST),
    {
      onSuccess: (res) => {
        if (!res) return
        redirectProject({ isRender: true })
      },
      onError
    }
  )

  const { mutateAsync: renderMutateAsync } = useMutation(
    (projectId: string) =>
      service().projects.changeStatus(projectId, PROJECT_STATUS.RENDER_REQUEST),
    { onError }
  )

  return { renderMutate, renderMutateAsync }
}

export const useTryOtherTemplateMutation = (
  redirectProject: (_: RedirectProjectParams) => void,
  renderToast: (props: ToastProps) => void,
  onClose: () => void,
  toastTitle: string,
  toastDescription: string
) => {
  const intl = useIntl()
  const { mutate: otherTemplateMutate } = useMutation<
    Awaited<Promise<{ aiRequestId: string; requestLang: string }>>,
    Error,
    string
  >(
    (aiRequestId: string) =>
      service().projects.newDesignByAiRequestId(aiRequestId),
    {
      onSuccess: (res) => {
        if (!res.aiRequestId) return
        redirectProject({
          isRender: true,
          aiRequestInfo: { id: res.aiRequestId, reqLang: res.requestLang }
        })
      },
      onError: (error) => {
        console.log('error', error)
        onClose()
        if (error instanceof Object && 'msg' in error && 'code' in error) {
          if (error.code === 'WAITING') {
            renderToast({
              icon: <IconCircleCaution />,
              title: toastTitle,
              description: toastDescription,
              duration: 4
            })
            return
          }
          if (typeof error.msg === 'string') {
            message.error(intl.formatMessage({ id: error.msg }))
          }
        }
      }
    }
  )
  return { otherTemplateMutate }
}
