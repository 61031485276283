import { useMutation, useQuery } from 'react-query'
import { service } from '../../utils/api'
import { AIInfos } from '../../types/project'
import { useAiStore } from '../../stores/ai'
import { sendMessageToParent } from '../Main/constants'
import { message } from 'antd'
import { useIntl } from 'react-intl'

export const useLeaveMutation = (
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const { aiInfos } = useAiStore()
  const reqId = aiInfos?._id
  const { mutate: leaveMutate } = useMutation(
    () => {
      return service().projects.readyComplete(reqId || '')
    },
    {
      onSuccess: (res) => {
        if (res !== 'SUCCESS') {
          throw new Error('code is not SUCCESS')
        }
        sendMessageToParent({
          code: 'ROUTE_PUSH',
          data: '/projects',
          isRender: true
        })
      },
      onError: (error) => {
        setIsLoading(false)
        console.log('error', error)
      }
    }
  )
  return { leaveMutate }
}

export const useAiQuery = () => {
  const intl = useIntl()
  const { aiInfos } = useAiStore()
  const reqId = aiInfos?._id

  const { data, refetch, isError } = useQuery<AIInfos>(
    ['ai'],
    () => service().projects.getAiInfo(reqId || ''),
    {
      enabled: !!reqId,
      cacheTime: 0,
      staleTime: 0,
      onSuccess: (res) => {
        if (!res) {
          throw new Error('res is not defined')
        }
      },
      onError: (error) => {
        if (error instanceof Error) {
          message.error(intl.formatMessage({ id: error.message }))
        }
      }
    }
  )

  return { aiInfo: data, refetchAiInfo: refetch, isError }
}

export const useIsHideQuery = () => {
  const { data: isHide, isFetching } = useQuery(['isHide'], () =>
    service().users.getIsHide()
  )

  return { isHide, isFetching }
}

export const useHideMutation = () => {
  const { mutateAsync: hideMutate } = useMutation(() =>
    service().users.setHide()
  )
  return { hideMutate }
}
