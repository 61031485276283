import { useCallback, useEffect, useState } from 'react'

import FFmpegService from '../../utils/libs/ffmpeg'

import GlobalStyles from '../../styles/GlobalStyles'
import Header from '../Header'
import Sidebar from '../Sidebar'
import Screen from '../Screen'

import 'react-color-palette/lib/css/styles.css'
import SceneList from '../SceneList/SceneList'
import ProgressBar from '../ProgressBar'
import EditModals from '../EditModals'
import {
  Content,
  Info,
  Layout,
  MainContainer,
  ScreenWrapper,
  Wrapper
} from './_stylesMain'
import useLocalStorage from '../../hooks/useLocalStorage'
import { Auth, AuthInitialValue } from '../../components/AuthHelper'
import TutorialModal from '../TutorialModal'
import BasicModal from '../NotificationModals/BasicModal'
import RenderModal from '../RenderModal'
import OtherTemplateModal from '../RenderModal/OtherTemplateModal'
import SignupModal from '../SignupModal'
import { iconModalInfo } from '../../assets/icons'
import DefaultModal from '../../components/DefaultModal'
import { useModal } from '../../hooks/useModal'
import { useToggleContext } from '../../contexts/ToggleContext'

import Typography from '../../components/Typography'
import theme from '../../styles/theme'
import useFormatText from '../../hooks/useFormatText'
import {
  getCount,
  getUserSourceCount,
  screenSourceCounts
} from '../Screen/constants'
import { useProjectContext } from '../../hooks/useProject'
import { sendMessageToParent, showAlreadyRenderModal } from './constants'
import { useIntl } from 'react-intl'
import { service } from '../../utils/api'
import { useAiStore } from '../../stores/ai'
import AiLoadingModal from '../AiLoadingModal'
import { redirectVplateLoginPage } from '../../utils/vplate'
import { useQsStore } from '../../stores/qs'
import { useUserLimitContext } from '../../contexts/UserLimitContext'
import useBreakpoint from '../../hooks/useBreakpoint'

interface MainProps {
  projectId: string
  status?: number
}

export interface RedirectProjectParams {
  isRender: boolean
  aiRequestInfo?: { id: string; reqLang: string }
}

const Main = ({ projectId, status }: MainProps) => {
  const intl = useIntl()
  const [auth] = useLocalStorage<Auth>(
    'vplateVideoEditorAuth',
    AuthInitialValue
  )

  const { userLimit } = useUserLimitContext()
  const { project, setProject, sceneList } = useProjectContext()
  const { aiInfos } = useAiStore()
  const queryStrings = useQsStore((state) => state.queryStrings)

  const [selectedSceneIndex, setSelectedSceneIndex] = useState(0)
  // (완료한 프로젝트) 수정 버튼을 누르고 아무것도 수정되지 않을 경우 렌더링 X
  const [isModified, setIsModified] = useState(false)
  const [isFlickering, setIsFlickering] = useState(false)
  const [processingSources, setProcessingSources] = useState<
    { sceneIndex: number; sourceIndex: number }[]
  >([])
  const [selectedSourceIndex, setSelectedSourceIndex] = useState(-1)
  const [isAutoLoading, setIsAutoLoading] = useState(false)

  const scenarioLimit = userLimit?.monthlyScenarioGen
  const scenarioCount = userLimit?.usedScenarioGen

  const restScenarioCount = Math.max(
    (scenarioLimit ?? 0) - Math.abs(scenarioCount ?? 0),
    0
  )

  const senarioCountExceededLimit = restScenarioCount === 0

  const fromProduct =
    !senarioCountExceededLimit && queryStrings.from === 'product'

  const isShowAiLoading =
    aiInfos?._id &&
    aiInfos?.requestType === 2 &&
    aiInfos?.status !== 6 &&
    !aiInfos?.isNewScenario

  const { isBreakpoint } = useBreakpoint()

  // ai 정보가 있으면 ai 탭을 기본으로 보여줌
  const defaultAiDrawer = !isShowAiLoading && aiInfos?.marketingPlan

  const [selectedDrawerIndex, setSelectedDrawerIndex] = useState(
    !(isBreakpoint('small') || isBreakpoint('medium')) && defaultAiDrawer
      ? 0
      : -1
  )
  const [userEmail, setUserEmail] = useState<string>('')
  const { isOpen } = useToggleContext()
  const isFromIframe = window.parent !== window

  const isShowGenerateScenarioLoading =
    aiInfos?._id &&
    aiInfos?.status !== 6 &&
    aiInfos?.isNewScenario &&
    aiInfos?.status !== -6

  const renderModal = useModal()
  const editModal = useModal()
  const tutorialModal = useModal()
  const basicModal = useModal()
  const signupModal = useModal()
  const errorModal = useModal()
  const aiLoadingModal = useModal()

  const errorModalTitle = useFormatText('ERROR_CONFIRM_MESSAGE')
  const errorModalButton = useFormatText('ERROR_CONFIRM_DONE')

  const isSourceProcessing = useCallback(
    (sceneIndex: number, sourceIndex: number) => {
      return !!processingSources.find(
        (item) =>
          item.sceneIndex === sceneIndex && item.sourceIndex === sourceIndex
      )
    },
    [processingSources]
  )

  const onSourceClick = useCallback(
    (index) => {
      if (!isSourceProcessing(selectedSceneIndex, index)) {
        const selectSource = sceneList?.[selectedSceneIndex]?.source?.[index]
        const selectedSourceType = selectSource?.sourceType

        // 로그인 안 되어 있을 때 이미지, 비디오 편집 불가
        const editorAuth = JSON.parse(
          localStorage.getItem('vplateVideoEditorAuth') || '{}'
        )
        const token = editorAuth?.token?.replace(/"/g, '')

        if (selectedSourceType !== 'T' && !token) {
          signupModal.open()
          return
        }

        setSelectedSourceIndex(index)
        editModal.open()
      }
    },
    [editModal, signupModal, isSourceProcessing, selectedSceneIndex, sceneList]
  )

  const redirectProject = ({
    isRender = false,
    aiRequestInfo
  }: RedirectProjectParams) => {
    const vplateVideoEditorAuth = JSON.parse(
      localStorage.getItem('vplateVideoEditorAuth') || '{}'
    )

    // 완료된 프로젝트를 아무것도 수정하지 않고, 다시 프로젝트 페이지로 돌아간다면 (나의 프로젝트) 해당 프로젝트의 모달을 띄워놓는 용도 => 에디 요청
    if (isFromIframe && project?.status && project.status >= 3 && !isModified) {
      sendMessageToParent({ code: 'PROJECT_DATA', data: project })
      return
    }

    // 플러그인 + 로그인 X
    if (!vplateVideoEditorAuth?.token) {
      redirectVplateLoginPage()
      return
    }

    // 그 외에는 그냥 프로젝트 페이지로 이동
    if (aiRequestInfo?.id) {
      const promotionPrice = aiInfos?.promotionPrice
      const includeDiscountPrice =
        aiInfos?.category === 101
          ? promotionPrice === undefined ||
            promotionPrice === null ||
            isNaN(promotionPrice)
            ? 0
            : 1
          : undefined

      const includeImage =
        aiInfos?.images && aiInfos?.images?.length > 0 ? 1 : 0
      const includeVideo =
        aiInfos?.videos && aiInfos?.videos?.length > 0 ? 2 : 0
      // 0 - 모든 템플릿 | 1 - 영상만 사용하는 템플릿 | 2 - 이미지만 사용하는 템플릿
      const resource =
        aiInfos?.videoInsertType === 2 ? 3 - (includeVideo + includeImage) : 0
      sendMessageToParent({
        code: 'ROUTE',
        data: `/store?aiRequestId=${aiRequestInfo.id}&category=${
          aiInfos?.category
        }&highlight=${aiInfos?.highlight}&redirect=true&reqLang=${
          aiRequestInfo?.reqLang ?? 'ko'
        }${
          includeDiscountPrice !== undefined
            ? `&includeDiscountPrice=${includeDiscountPrice}`
            : ''
        }&resource=${resource}&from=editor`,
        isRender
      })
    } else sendMessageToParent({ code: 'ROUTE', data: '/projects', isRender })
  }

  const handleHeaderCompleteClick = async () => {
    const vplateVideoEditorAuth = JSON.parse(
      localStorage.getItem('vplateVideoEditorAuth') || '{}'
    )
    if (vplateVideoEditorAuth?.token) {
      if (!project?.userId) await patchUserId()
      renderModal.open()
    } else {
      signupModal.open()
    }
  }

  const onCloseErrorModal = () => errorModal.close()

  const sceneSources = project?.sources?.[selectedSceneIndex].map(
    (_item, idx) => {
      return sceneList?.[selectedSceneIndex]?.source?.[idx]
    }
  )

  const userSources = project?.sources?.[selectedSceneIndex].map(
    (item, idx) => {
      return {
        value: item,
        sourceType: sceneSources && sceneSources[idx]?.sourceType
      }
    }
  )

  const patchUserId = async () => {
    try {
      if (!project || !setProject || project?.userId) return
      const result = await service().users.getUserInfo()
      if (!result || !result?._id) {
        throw new Error('userInfo is not exist')
      }
      setUserEmail(result?.userEmail)
      setProject({
        ...project,
        userId: result._id
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!isFromIframe && status && status >= 3) {
      showAlreadyRenderModal(intl, redirectProject)
    }
  }, [isFromIframe, status])

  useEffect(() => {
    const vplateVideoEditorAuth = JSON.parse(
      localStorage.getItem('vplateVideoEditorAuth') || '{}'
    )
    if (!isFromIframe && !vplateVideoEditorAuth?.token) {
      tutorialModal.open()
    }
  }, [isFromIframe])

  useEffect(() => {
    if (auth?.token && project) {
      patchUserId()
    }
  }, [project])

  useEffect(() => {
    FFmpegService.init()
    return () => {
      FFmpegService.ffmpeg.exit()
    }
  }, [])

  useEffect(() => {
    // TODO: 주석코드는 복원, 아래 코드는 주석 후 push
    // aiLoadingModal.open()

    if (isShowAiLoading && isFromIframe) {
      aiLoadingModal.open()
      if (!(isBreakpoint('small') || isBreakpoint('medium')))
        setSelectedDrawerIndex(0)
    }
  }, [isShowAiLoading, isFromIframe])

  return (
    <Wrapper className='vplateVideoEditorWrapper'>
      <GlobalStyles />

      <Header
        isModified={isModified}
        onCompleteClick={handleHeaderCompleteClick}
        setSelectedSceneIndex={setSelectedSceneIndex}
        setIsFlickering={setIsFlickering}
        basicModal={basicModal}
        redirectProject={redirectProject}
      />
      <Layout>
        <Content $isOpen={isOpen}>
          <MainContainer $isOpen={isOpen}>
            <ScreenWrapper>
              <Screen
                selectedSceneIndex={selectedSceneIndex}
                isSourceProcessing={isSourceProcessing}
                isFlickering={isFlickering}
                onSourceClick={onSourceClick}
              />
            </ScreenWrapper>
            <ProgressBar />
            <Info>
              <Typography
                type='body2'
                style={{ color: theme.colors.text.primary, marginRight: 24 }}
              >
                {useFormatText('SCENE_COMPONENT')}
              </Typography>
              {screenSourceCounts.map(({ icon, type }) => {
                return (
                  <div
                    key={type}
                    style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                  >
                    {icon}
                    <Typography
                      type='body2'
                      style={{
                        color: theme.colors.text.secondary,
                        marginRight: 8
                      }}
                    >
                      {getUserSourceCount(userSources, type)}/
                      {getCount(sceneSources, type)}
                    </Typography>
                  </div>
                )
              })}
            </Info>
            <SceneList
              selectedSceneIndex={selectedSceneIndex}
              onSceneChange={(index) => {
                setSelectedSceneIndex(index)
                setIsFlickering(false)
              }}
            />
          </MainContainer>
        </Content>
      </Layout>
      <Sidebar
        setIsModified={setIsModified}
        selectedDrawerIndex={selectedDrawerIndex}
        setIsAutoLoading={setIsAutoLoading}
        setSelectedDrawerIndex={setSelectedDrawerIndex}
      />
      <EditModals
        editModal={editModal}
        selectedSourceIndex={selectedSourceIndex}
        selectedSceneIndex={selectedSceneIndex}
        setProcessingSources={setProcessingSources}
        setIsModified={setIsModified}
      />
      {tutorialModal.visible && (
        <TutorialModal onClose={() => tutorialModal.close()} />
      )}
      {basicModal.visible && <BasicModal onClose={() => basicModal.close()} />}
      {renderModal.visible &&
        (fromProduct ? (
          <OtherTemplateModal
            projectId={projectId}
            projectTemplateTitle={project?.design?.temTitle ?? ''}
            onClose={() => renderModal.close()}
            redirectProject={redirectProject}
            userEmail={userEmail}
          />
        ) : (
          <RenderModal
            projectId={projectId}
            projectTemplateTitle={project?.design?.temTitle ?? ''}
            onClose={() => renderModal.close()}
            redirectProject={redirectProject}
            userEmail={userEmail}
          />
        ))}
      {signupModal.visible && (
        <SignupModal
          projectId={projectId}
          handleCloseBtnClick={() => signupModal.close()}
          isFromIframe={isFromIframe}
        />
      )}
      {errorModal.visible && (
        <DefaultModal
          icon={iconModalInfo}
          title={errorModalTitle}
          confirmBtnText={errorModalButton}
          onClose={onCloseErrorModal}
          onConfirm={onCloseErrorModal}
        />
      )}
      {/* 제품 정보로 시작할 때 생성되는 ai 모달 */}
      {aiLoadingModal.visible && (
        <AiLoadingModal onClose={() => aiLoadingModal.close()} />
      )}
      {/* 직접 기획에서 생성되는 ai 모달 */}
      {(isAutoLoading || isShowGenerateScenarioLoading) && (
        <AiLoadingModal
          onClose={() => setIsAutoLoading(false)}
          isGenerateScenario={isAutoLoading || !!isShowGenerateScenarioLoading}
        />
      )}
    </Wrapper>
  )
}

export default Main
