import styled, { css } from 'styled-components'
import { IconScenario } from '../../assets/icons'
import { AIInfos } from '../../types/project'
import { tabletMedia } from './constants'
import AiSubtitle from './AiSubtitle'
import useToggle from '../../hooks/useToggle'
import useAutoScroll from '../../hooks/useAutoScroll'
import useFormatText from '../../hooks/useFormatText'
import ContentCard, {
  ContentCardProps
} from '../../components/ModalContentCard'
import LottieIcon from '../../components/Lottie'
import useMarketingPlan from './hooks/useMarketingPlan'

const MarketingInfo = ({
  aiInfo,
  progress,
  isPendingError,
  isMobile,
  showOnlyMarketingInfo
}: // isReset
{
  aiInfo?: AIInfos
  progress?: number
  isPendingError: boolean
  isMobile: boolean
  showOnlyMarketingInfo: boolean
  // isReset: boolean
}) => {
  const { isToggled, toggle } = useToggle(true)
  const scrollRef = useAutoScroll<HTMLDivElement>()

  const isKr = localStorage.getItem('locale')?.includes('ko') ?? false

  const { isLoading, isStarting, currentContent } = useMarketingPlan({
    priceInfo: aiInfo?.priceInfo,
    isNewScenario: aiInfo?.isNewScenario,
    progress
  })

  const currentCategory = aiInfo?.category
  const currentPersona = currentContent(aiInfo?.persona)
  const currentHooking = currentContent(aiInfo?.hooking)
  const currentMoving = currentContent(aiInfo?.moving)
  const currentPromotionTitle = currentContent(aiInfo?.promotionTitle)
  const currentPromotionDetail = currentContent(aiInfo?.promotionDetail)

  const isPersona =
    currentPersona?.lifestyle ||
    currentPersona?.interest ||
    currentPersona?.feature

  const isInformation = !!currentCategory && currentCategory === 102
  const isPromotion = !!currentCategory && currentCategory === 101

  const ProductContents: ContentCardProps[] = [
    ...(currentPersona
      ? [
          {
            title: 'AI_LOADING_MODAL_SUBTITLE4',
            contentList: [
              {
                title: currentPersona?.character,
                content: isPersona
                  ? [
                      currentPersona?.lifestyle || '',
                      currentPersona?.interest || '',
                      currentPersona?.feature || ''
                    ]
                  : undefined
              }
            ]
          }
        ]
      : []),
    ...(isInformation && currentHooking
      ? [
          {
            title: 'AI_LOADING_MODAL_SUBTITLE5',
            contentList: [
              {
                content: [
                  currentHooking?.short || '',
                  currentHooking?.detailed || ''
                ]
              }
            ]
          }
        ]
      : []),
    ...(isInformation && currentMoving
      ? [
          {
            title: 'AI_LOADING_MODAL_SUBTITLE6',
            contentList: [
              {
                content: [
                  currentMoving?.short || '',
                  currentMoving?.detailed || ''
                ]
              }
            ]
          }
        ]
      : []),
    ...(isPromotion && currentPromotionTitle
      ? [
          {
            title: 'AI_LOADING_MODAL_SUBTITLE7',
            contentList: [
              {
                content: [currentPromotionTitle || '']
              }
            ]
          }
        ]
      : []),
    ...(isPromotion && currentPromotionDetail
      ? [
          {
            title: 'AI_LOADING_MODAL_SUBTITLE8',
            contentList: [
              {
                content: [currentPromotionDetail || '']
              }
            ]
          }
        ]
      : [])
  ]

  return (
    <MarketingInfoStyle.Wrapper showOnlyMarketingInfo={showOnlyMarketingInfo}>
      <AiSubtitle
        isToggled={isToggled}
        toggle={toggle}
        subtitle={useFormatText('AI_LOADING_MODAL_STEP2')}
        progressValue={progress || 0}
        progressText={showOnlyMarketingInfo ? '1' : '2'}
        icon={<IconScenario />}
        isShowArrow={isMobile}
      />
      {isToggled && (
        <MarketingInfoStyle.Container
          ref={scrollRef}
          isEmpty={!aiInfo}
          showOnlyMarketingInfo={showOnlyMarketingInfo}
          isKr={isKr}
        >
          {(isStarting &&
            ProductContents.map((content, idx) => (
              <ContentCard
                key={'ai-modal-product-' + idx}
                title={useFormatText(content.title)}
                content={content.content}
                contentList={content.contentList}
                notice={content.notice}
              />
            ))) || <></>}
          {isLoading && (
            <div className='empty'>
              <LottieIcon width={34} height={34} />
              {useFormatText(
                !isPendingError
                  ? 'AI_LOADING_PLANNING_PENDING'
                  : 'AI_LOADING_PLANNING_PROCESSING'
              ) || <></>}
            </div>
          )}
        </MarketingInfoStyle.Container>
      )}
    </MarketingInfoStyle.Wrapper>
  )
}

export default MarketingInfo

const MarketingInfoStyle = {
  Wrapper: styled.div<{ showOnlyMarketingInfo?: boolean }>`
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 8px
      ${({ showOnlyMarketingInfo }) => (showOnlyMarketingInfo ? '0' : '16px')};
    user-select: none;

    ${({ theme }) =>
      theme.breakpoints.medium(
        css`
          padding: 0;
        `
      )};
  `,
  Container: styled.div<{
    isEmpty: boolean
    isKr: boolean
    showOnlyMarketingInfo?: boolean
  }>`
    width: ${({ showOnlyMarketingInfo }) =>
      showOnlyMarketingInfo ? '100%' : '429px'};
    height: calc(100% - 36px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: ${({ theme }) => theme.colors.f5f5};
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 24px 18px;
    margin-top: 12px;

    ${({ isEmpty }) =>
      isEmpty &&
      css`
        color: ${({ theme }) => theme.colors.text['#aaa']};
        justify-content: center;
      `};

    .empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 12px;
      color: ${({ theme }) => theme.colors.text.secondary};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    ${tabletMedia(css`
      width: 100%;
    `)};

    ${({ theme }) =>
      theme.breakpoints.medium(
        css`
          width: 100%;
          height: 236px;
          gap: 25px;
          padding: 20px 16px;
        `
      )};
  `
}
