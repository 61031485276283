import styled, { css } from 'styled-components'
import theme from '../../styles/theme'
import LeaveButton from './LeaveButton'
import AiProgress from './AiProgress'
import useTablet from './hooks/useTablet'
import { getTitle, tabletMedia } from './constants'
import { useIntl } from 'react-intl'
import useFormatText from '../../hooks/useFormatText'
import { AIInfos } from '../../types/project'

const AiTitle = ({
  isMobile,
  isPendingError,
  progress,
  isAutoRender,
  mediaTotalLength,
  showOnlyMarketingInfo
}: {
  isMobile: boolean
  isPendingError: boolean
  progress?: AIInfos['progress']
  mediaTotalLength?: number | undefined
  isAutoRender?: boolean
  showOnlyMarketingInfo: boolean
}) => {
  const intl = useIntl()
  const { isTablet } = useTablet()

  const renderStep = (stepTextKey: string, progressValue?: number) => {
    const isCurrent = progressValue !== undefined && progressValue > 0
    return (
      <Text isCurrent={isCurrent}>
        {intl.formatMessage({ id: stepTextKey })}
      </Text>
    )
  }

  return (
    <Wrapper isMobile={isMobile} showOnlyMarketingInfo={showOnlyMarketingInfo}>
      <TextContainer
        isMobile={isMobile}
        showOnlyMarketingInfo={showOnlyMarketingInfo}
      >
        <div className='title'>{useFormatText('AI_LOADING_MODAL_TITLE')}</div>
        <div className='subtitle'>
          <div>
            {useFormatText(
              getTitle(showOnlyMarketingInfo || isTablet, isPendingError)
            )}
          </div>
        </div>
      </TextContainer>
      {!isTablet && !isMobile && !showOnlyMarketingInfo && (
        <div style={{ marginTop: 20 }}>
          <LeaveButton isAutoRender={isAutoRender} />
          <div
            style={{ display: 'flex', gap: 8, marginTop: 36, marginLeft: 13 }}
          >
            <ProgressWrapper>
              <AiProgress text='1' value={progress?.productProgress || 0} />
              <Stick />
              <AiProgress text='2' value={progress?.marketingProgress || 0} />
              {!!mediaTotalLength && (
                <>
                  <Stick />
                  <AiProgress text='3' value={progress?.mediaProgress || 0} />
                </>
              )}
            </ProgressWrapper>
            <TextWrapper>
              {renderStep('AI_LOADING_MODAL_STEP1', progress?.productProgress)}
              {renderStep(
                'AI_LOADING_MODAL_STEP2',
                progress?.marketingProgress
              )}
              {!!mediaTotalLength &&
                renderStep('AI_LOADING_MODAL_STEP3', progress?.mediaProgress)}
            </TextWrapper>
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default AiTitle

const common = css`
  max-width: 100%;
  border-right: none;
  margin-right: 0;
  padding: 0;
`
const Wrapper = styled.div<{
  isMobile: boolean
  showOnlyMarketingInfo: boolean
}>`
  width: 100%;
  max-width: 286px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid ${theme.colors.border.primary};
  user-select: none;
  padding: 16px;
  margin-right: 20px;

  ${({ showOnlyMarketingInfo }) =>
    showOnlyMarketingInfo ? common : tabletMedia(common)};

  ${({ isMobile }) => isMobile && common};

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding: 0;
    `)};
`

const TextContainer = styled.div<{
  isMobile: boolean
  showOnlyMarketingInfo: boolean
}>`
  display: flex;
  flex-direction: column;

  ${({ theme }) =>
    theme.breakpoints.medium(css`
      padding-bottom: 27px;
    `)};

  .title {
    color: ${theme.colors.black2};
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
    white-space: pre;

    ${tabletMedia(css`
      font-size: 20px;
    `)};
  }

  .subtitle {
    color: ${theme.colors.text['#666']};
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    white-space: pre-wrap;
    margin-top: 16px;

    ${({ showOnlyMarketingInfo }) =>
      showOnlyMarketingInfo
        ? css`
            margin-top: 12px;
            margin-bottom: 20px;
          `
        : tabletMedia(css`
            margin-top: 12px;
            margin-bottom: 20px;
          `)};
  }

  ${({ showOnlyMarketingInfo }) =>
    showOnlyMarketingInfo
      ? css`
          margin-top: 0;
          padding-left: 0;
          padding-right: 0;
        `
      : tabletMedia(css`
          margin-top: 0;
          padding-left: 0;
          padding-right: 0;
        `)};

  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
      align-items: center;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 0;

      .title {
        width: 100%;
        height: 56px;
        font-size: 20px;
        text-align: center;
        margin-top: 0;
        padding-top: 17px;
        padding-bottom: 17px;
      }

      .subtitle {
        width: 100%;
        max-width: 450px;
        height: 40px;
        text-align: center;
        word-break: keep-all;
        white-space: initial;
        margin-top: 8px;
        padding-left: 16px;
        padding-right: 16px;
      }
    `};
`

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
`

const Stick = styled.div`
  width: 5px;
  height: 58px;
  flex-shrink: 0;
  border-radius: 4px;
  background-color: ${theme.colors.eded};
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 71px;
`

const Text = styled.div<{ isCurrent: boolean }>`
  color: ${theme.colors.text['#888']};
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  ${({ isCurrent }) =>
    isCurrent &&
    css`
      color: ${theme.colors.black2};
      font-weight: 600;
    `};
`
