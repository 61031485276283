import { message } from 'antd'
import DefaultModal from '../../components/DefaultModal'
import { iconModalComplete } from '../../assets/icons'
import { useProjectContext } from '../../hooks/useProject'
import useFormatText from '../../hooks/useFormatText'
import useToast from '../../hooks/useToast'
import {
  useRenderProjectMutation,
  useTryOtherTemplateMutation
} from './_queries'

import ReactGA from 'react-ga4'
import useGetIsTrackingEventVerified from '../../hooks/useGetIsTrackingEventVerified'
import { redirectVplateLoginPage } from '../../utils/vplate'
import { RedirectProjectParams } from '../Main'
import { useAiStore } from '../../stores/ai'
interface RenderModalProps {
  projectId: string
  projectTemplateTitle: string
  onClose: () => void
  redirectProject: (_: RedirectProjectParams) => void
  userEmail: string
}

const OtherTemplateModal = ({
  onClose,
  projectId,
  projectTemplateTitle,
  redirectProject,
  userEmail
}: RenderModalProps) => {
  const { isLimit } = useProjectContext()
  const toastTitle = useFormatText('RENDER_LIMIT_TITLE')
  const toastDescription = useFormatText('RENDER_LIMIT_DESCRIPTION', {
    email: userEmail
  })
  const { renderToast } = useToast()
  const aiInfos = useAiStore((state) => state.aiInfos)
  const { renderMutate, renderMutateAsync } = useRenderProjectMutation(
    redirectProject,
    renderToast,
    onClose,
    toastTitle,
    toastDescription
  )
  const { otherTemplateMutate } = useTryOtherTemplateMutation(
    redirectProject,
    renderToast,
    onClose,
    toastTitle,
    toastDescription
  )
  const isTrackingEventVerified = useGetIsTrackingEventVerified()

  const renderModalTitle = useFormatText('TRY_OTHER_TEMPLATE_MODAL_TITLE')
  const renderModalContent = useFormatText('TRY_OTHER_TEMPLATE_MODAL_CONTENT')
  const renderModalSemiConfirmBtnText = useFormatText('CONFIRM_DONE')
  const renderModalConfirmBtnText = useFormatText(
    'TRY_OTHER_TEMPLATE_MODAL_CONFIRM'
  )
  const renderModalErrorMsg = useFormatText('RENDER_COUNT_EXCEEDED')
  const changeStatusErrorMsg = useFormatText('CHANGE_STATUE_ERROR_MESSAGE')

  const handleConfirmButtonClick = async (isOtherTemplate: boolean) => {
    if (isLimit) {
      message.error(renderModalErrorMsg)
      onClose()
      return
    }
    try {
      const vplateVideoEditorAuth = JSON.parse(
        window.localStorage.getItem('vplateVideoEditorAuth') || '{}'
      )
      if (!vplateVideoEditorAuth?.token) {
        redirectVplateLoginPage()
      }

      if (isTrackingEventVerified && projectTemplateTitle) {
        ReactGA.event({
          category: 'Start',
          action: 'ChooseTemplate',
          label: projectTemplateTitle
        })
      }

      if (!isOtherTemplate || (isOtherTemplate && !aiInfos?._id)) {
        renderMutate(projectId)
      } else {
        const resp = await renderMutateAsync(projectId)
        if (resp) otherTemplateMutate(aiInfos._id!)
      }
    } catch (error) {
      console.log('error', error)
      onClose()
      message.error(changeStatusErrorMsg)
    }
  }

  return (
    <DefaultModal
      icon={iconModalComplete}
      title={renderModalTitle}
      content={renderModalContent}
      semiConfirmBtnText={renderModalSemiConfirmBtnText}
      confirmBtnText={renderModalConfirmBtnText}
      onClose={onClose}
      onSemiConfirm={() => handleConfirmButtonClick(false)}
      onConfirm={() => handleConfirmButtonClick(true)}
    />
  )
}

export default OtherTemplateModal
