import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { InfiniteData } from 'react-query'
import { IconBlank } from '../../assets/icons'
import AspectRatioSizer from '../../components/AspectRatioSizer'
import PhotoFilter from '../../components/Dropdown/PhotoFilter'
import InputElement from '../../components/Input'
import Tabs from '../../components/Tabs'
import {
  PhotoData,
  ResultPhotoProps,
  ResultVideoProps
} from '../../utils/api/images'
import {
  BlankWrapper,
  Content,
  ImageList,
  ImageListItem,
  InputWrapper,
  LottieWrapper,
  ObserveElement,
  SearchForm
} from './_stylesModal'
import AIImageList from '../../components/AIPanel/AIImageList'
import { useIntl } from 'react-intl'
import { css } from 'styled-components'
import Storage from './Storage'
import { useAiStore } from '../../stores/ai'

interface PhotoModalTabsProps {
  handleSearch: (e: React.FormEvent<HTMLFormElement>) => void
  showDropbox: boolean
  setOrientation: Dispatch<SetStateAction<string>>
  imageList:
    | InfiniteData<ResultPhotoProps | ResultVideoProps | undefined>
    | undefined
  isFetching: boolean
  listLoad: () => React.ReactNode
  fetchNextPage: () => void
  selectedImageUrl: string
  setSelectedImageUrl: Dispatch<SetStateAction<string>>
  previewLoadStart: () => void
  previewLoading: boolean
  setSelectedAssetInfo: Dispatch<SetStateAction<any>>
  selectedTabIndex: number
  setSelectedTabIndex: Dispatch<SetStateAction<number>>
}

const PhotoModalTabs = (props: PhotoModalTabsProps) => {
  const {
    handleSearch,
    showDropbox,
    setOrientation,
    imageList,
    isFetching,
    listLoad,
    fetchNextPage,
    selectedImageUrl,
    setSelectedImageUrl,
    previewLoadStart,
    previewLoading,
    setSelectedAssetInfo,
    selectedTabIndex,
    setSelectedTabIndex
  } = props

  const intl = useIntl()
  const { formatMessage } = intl
  const { aiInfos } = useAiStore()
  const isPlugin = (aiInfos?.adPlanning || [])?.length > 0

  // const { uploadMutate } = useUploadImageQuery(setSelectedImageUrl)

  const hasResult = imageList?.pages[0] && imageList.pages[0]?.total > 0
  const { ref: observeRef, inView } = useInView({
    threshold: 0,
    triggerOnce: true
  })

  useEffect(() => {
    inView && fetchNextPage()
  }, [fetchNextPage, inView])

  const onClickImage = (img: PhotoData | string) => {
    const image = typeof img === 'string' ? img : img?.image
    const str = typeof img === 'string' ? '' : '?w=1280'
    const url = `${image}${str}`
    if (selectedImageUrl === url) return
    setSelectedImageUrl(url)
    previewLoadStart()
  }

  const list =
    imageList &&
    imageList.pages.map((page) => {
      return page?.data.map((d) => {
        const image = d as PhotoData
        return (
          <ImageListItem
            key={image.id}
            src={image.thumbnail}
            selected={image?.image === selectedImageUrl}
            onClick={() => {
              onClickImage(image)
              setSelectedAssetInfo(null)
            }}
            isPreviewLoading={previewLoading}
          >
            <AspectRatioSizer aspect={16 / 9} />
          </ImageListItem>
        )
      })
    })

  const getTabs = () => {
    const freeTab = {
      title: formatMessage({ id: 'IMAGE_INPUT_FROM_FREESTOCK' }),
      children: (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative'
          }}
        >
          <SearchForm
            innerHeight={document.documentElement.clientHeight}
            onSubmit={handleSearch}
          >
            <InputWrapper>
              <InputElement
                name='query'
                placeholder={formatMessage({
                  id: 'IMAGE_INPUT_FROM_FREESTOCK_SEARCH_PLACEHOLDER'
                })}
                block
                type='search'
                inputMode='search'
                autoComplete='off'
                style={{
                  zIndex: 200
                }}
              />
              {showDropbox && <PhotoFilter setOrientaion={setOrientation} />}
            </InputWrapper>
          </SearchForm>
          <Content>
            <div
              style={{
                height: '100%',
                overflowY: 'auto'
              }}
            >
              {hasResult ? (
                <ImageList>{list}</ImageList>
              ) : (
                <>
                  {!isFetching && (
                    <BlankWrapper>
                      <IconBlank />
                      <span>{formatMessage({ id: 'NO_SEARCH_RESULT' })}</span>
                    </BlankWrapper>
                  )}
                </>
              )}
              {isFetching ? (
                <LottieWrapper>{listLoad()}</LottieWrapper>
              ) : (
                <>{hasResult && <ObserveElement ref={observeRef} />}</>
              )}
            </div>
          </Content>
        </div>
      ),
      onClick: () => setSelectedTabIndex(isPlugin ? 1 : 0)
    }

    const aiTab = {
      title: formatMessage({ id: 'IMAGE_INPUT_FROM_AI' }),
      children: (
        <AIImageList
          aiImages={aiInfos.images}
          scenario={aiInfos?.scenario}
          rowStyle={{
            height: '50vh',
            overflowY: 'auto'
          }}
          onClickImage={(image) => {
            onClickImage(image)
            setSelectedAssetInfo(null)
          }}
          usedPoint={aiInfos?.usedPoint}
          emptyTextStyle={{ marginTop: '70px' }}
        />
      ),
      onClick: () => setSelectedTabIndex(isPlugin ? 0 : -1)
    }

    const assetTab = {
      title: formatMessage({ id: 'IMAGE_INPUT_FROM_STORAGE' }),
      children: (
        <Storage
          onClickItem={(asset: any) => {
            if (!asset) return
            onClickImage(asset?.convertedUrl || asset?.originalUrl)
            setSelectedAssetInfo(asset)
          }}
          type='image'
        />
      ),
      onClick: () => setSelectedTabIndex(isPlugin ? 2 : 1)
    }

    if (isPlugin) {
      return [aiTab, freeTab, assetTab]
    }
    return [freeTab, assetTab]
  }

  return (
    <Tabs
      tabs={getTabs()}
      selectedTabIndex={selectedTabIndex}
      wrapperStyle={wrapperStyle}
    />
  )
}

export default PhotoModalTabs

const wrapperStyle = css`
  .tab-content {
    height: calc(100% - 52px);
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;

    ${({ theme }) => theme.breakpoints.medium`
        height: calc(100% - 35px);
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;
    `};
  }

  .tab-list-item {
    &:first-of-type {
      margin-left: 0;
    }
  }
`
